import React from 'react';

import './Footer.css';

class Footer extends React.Component {

  render() {
    const currentYear = new Date().getFullYear()
    return (
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-6 col-xs-12">
              <ul className="footer-links">
                <li>
                  <a className="page-scroll" href="#hero-area">Начало</a>
                </li>
                <li>
                  <a className="page-scroll" href="#portfolios">Портфолио</a>
                </li>
                <li>
                  <a className="page-scroll" href="#calc">Расчет</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-sm-6 col-xs-12">
              <div className="copyright">
                <p>&copy; 2018-{currentYear} <a rel="nofollow" href="https://uideck.com">UIdeck</a></p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
