import React from 'react';

import './SectionHeader.css';

class SectionHeader extends React.Component {

  render(){
    return (
        <div className="section-header">          
          <h2 className={"section-title "+(this.props.addClass ? this.props.addClass : '') }>{this.props.title}</h2>
          <hr className="lines" />
          <p className={"section-subtitle "+(this.props.addClass ? this.props.addClass : '')  }>{this.props.description}</p>
        </div>
    );
  }

}

export default SectionHeader;
