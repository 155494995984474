import React from 'react';
import { Map, Placemark } from "react-yandex-maps";

const center = [59.877617, 30.492491];
const marker = [59.87432849367428, 30.490230917930607];
const zoom = 13;

const YandexMap = () => {
    return (
        <Map defaultState={{ center, zoom }} height={'100%'} width={'100%'}>
            <Placemark
                geometry={marker}
                options={{
                    iconLayout: 'default#image',
                    iconImageHref: './img/mapicon.png',
                    iconImageSize: [128, 128],
                    iconImageOffset: [-64, -128]
                }} />
        </Map>
    )
}

export default React.memo(YandexMap);