import React from 'react';

import './Nav.css';

const sections = document.getElementsByClassName("section");

function getActiveSection(winScroll) {
  for (const section of sections) {
    const box = section.getBoundingClientRect()
      if(box.y <=100 && box.y >= -200 ){
      return section.id
    }
  }
}

class Nav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeItem : Object.keys(this.props.navData)[0],
      collapse: false
    }
    this.navClick = this.navClick.bind(this)
  }


  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll)
  }
  componentWillUnmount(){
    window.removeEventListener('scroll', this.listenToScroll)
  }

  listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop
    if (winScroll > 150) this.setState({ collapse: true })
    else this.setState({ collapse: false })


    const s = getActiveSection(winScroll)

    if (this.props.navData.hasOwnProperty('#'+s)){
      this.setState({activeItem: '#'+s})
    }


  }
  navClick(e){
    const a = e.target.getAttribute("href")
    this.setState({activeItem: a})
  }

  render() {
    const collapse = this.state.collapse;
    return (
      <div className={"navbar navbar-expand-lg fixed-top scrolling-navbar indigo " + (collapse ? 'top-nav-collapse' : '')}>
        <div className="container">
          <div className="navbar-header">
            <a href="index.html" className="navbar-brand"><img className="img-fulid" src="img/logo3.png" alt=""></img> </a>
          </div>

          <div className="collapse navbar-collapse" id="main-navbar">
            <ul className="navbar-nav mr-auto w-100 justify-content-end">

              {Object.keys(this.props.navData).map((link, index) => {
                const activeItem = (this.state.activeItem === link) 
                return (
                  <li className="nav-item"  key={index}>
                    <a className={"nav-link page-scroll" + (activeItem ? ' active' : '')} 
                      href={link}  onClick={this.navClick}>
                        {this.props.navData[link]}
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}



export default Nav;

