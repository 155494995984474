import React from 'react';
import PhoneInput from './PhoneInput'
import './Callback.css';
import ScrollAnimation from 'react-animate-on-scroll';


async function sendRequest(phone) {

  const url = '/php/sendrequest.php';
  const formData = new FormData();
  formData.append('phone', phone);
  formData.append('site', document.location.hostname);
  try {
    fetch(url, { method: 'POST', body: formData })
  } catch (error) {
    console.log(error);
  }

}

export default class CallBack extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: false,
      calling: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)

  }
  handleSubmit(event) {
    event.preventDefault();
    const str = event.target.phone.value.replace(/[^0-9.,]/g, "")

    setTimeout(() =>
      this.setState({ error: false }) // for animation purpose
      , 500)

    if (str.length === 10) {
      this.setState({ error: false })
      this.setState({ calling: true })
      sendRequest('+7' + str)
    } else {
      this.setState({ error: true })
      this.setState({ calling: false })
    }

  }




  render() {

    return (
      <section className="video-promo section callBackDiv">
        <div className="container ">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="video-promo-content text-center">
                <ScrollAnimation animateIn='animate__animated animate__zoomIn'>
                  <h2 className="">Заказать обратный звонок</h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn='animate__animated animate__zoomIn'>
                  <p className="">Позвоните нам:&nbsp;
                    <a href="tel:+78129858797" className="phoneBold">(812) 985-87-97</a>
                    <br />
                    или закажите звонок:</p>
                </ScrollAnimation>
                <form className="form-inline" onSubmit={this.handleSubmit}>
                  <div className={"col phoneInputsArea " + (this.state.error ? 'animate__animated animate__shakeX' : '')}>
                    <span className="phoneBold textShadow">+7 </span>
                    <PhoneInput />
                  </div>
                  <div className="col phoneInputsArea">
                    <button type='submit' className="filter btn callbtn"><span>Позвоните мне</span></button>
                  </div>
                </form>
                {this.state.calling && (
                  <ScrollAnimation animateIn='animate__animated animate__zoomIn'>
                    <p className="callConfirm">Перезвоним Вам максимально скоро!</p>
                  </ScrollAnimation>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>


    );
  }

}



