
import { pvcParams } from './pvcParams'

export default function calculator(w, h, size, season, overcross, install, price) {

    const calc = countStrips(w, size, overcross)
    const strips = calc.totalStrips
    const doubledGrebenka = (install === "doubled") ? 2 : 1

    let total = strips * h * price['' + season + '_' + size + '']

    total += strips * price['plastina_' + size + '']
    total += strips * size / 1000 * price['assemble']
    total += (w * doubledGrebenka).toFixed(2) * price['grebenka']
    total += parseInt(price.installationPrice[install])

    total = total.toFixed(2)
    const item = 'Размер ' + w + 'x' + h + '(h) м. Морозостойкая пленка ' + size + ' мм, нахлест  ' + overcross + '% (Полос: ' + strips + ')'

    return {
        item: item,
        strips: strips,
        total: total
    }

}

function countStrips(w, size, overcross) {
    const teethLength = 0.041   // !!! 0.417
    let currentRow = 0
    const z = Math.round(+w / teethLength);

    const pHoles = pvcParams[size]['pHoles']
    const shiftHoles = pvcParams[size]['shiftHoles'][overcross]
    const overHole = Math.ceil((pHoles - shiftHoles) / 2)

    let r1 = 0
    let r2 = 0

    let dz = z - pHoles

    r1 += Math.trunc(dz / (pHoles + shiftHoles))
    r2 = r1
    dz = dz - r1 * (pHoles + shiftHoles)
    r1 += 1
    currentRow = 1

    if (dz >= (shiftHoles + overHole)) {
        r2++;
        dz = Math.round(dz - (shiftHoles + overHole));
        currentRow = 2
    }

    let offsetLeftStrip = 0
    let offsetRightStrip = 0
    if (dz > 0) {

        let dx = pHoles - overHole - dz;
        offsetRightStrip = div(dx, 2);
        offsetLeftStrip = dx - offsetRightStrip;

        if (currentRow === 2) {
            r1++;
        } else {
            r2++;
        }
    }

    return {
        width: w,
        realwidth: (z * teethLength).toFixed(2),
        teeths: z,
        totalStrips: r1 + r2,
        row1strips: r1,
        row2strips: r2,
        freeteeths: dz,
        lastStripRowInfo: currentRow,
        size: size,
        overcross: overcross,
        offsetRightStrip: offsetRightStrip,
        offsetLeftStrip: offsetLeftStrip
    }
}

function div(val, by) {
    return (val - val % by) / by
}