import React from 'react';
import './Header.css';

import Nav from '../Nav/Nav';
import NavMobile from '../Nav/NavMobile';

import ScrollAnimation from 'react-animate-on-scroll'

const navData = {
  '#hero-area': 'Начало',
  '#services': 'Услуги',
  '#features': 'Преимущества',
  '#portfolios': 'Способ установки',
  '#calc': 'Расчет',
  '#contact': 'Контакты',
}

class Header extends React.Component {

  render() {
    return (
      <header id="hero-area" className='section'>

        {this.props.isMobile && <NavMobile navData={navData} />}
        {!this.props.isMobile && <Nav navData={navData} />}

        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-10">
              <div className="contents text-center">
                <ScrollAnimation animateIn='animate__animated animate__fadeInDown'>
                  <h1 className="textShadow">ПВХ завесы для рефрижераторов</h1>
                </ScrollAnimation>
                <ScrollAnimation animateIn='animate__animated animate__fadeIn'>
                  <p className="lead textShadow">Устанавливаем в день обращения</p>
                </ScrollAnimation>
                <ScrollAnimation animateIn='animate__animated animate__fadeInUp'>
                  <a href="#calc" className="btn btn-common page-scroll" >Рассчитать</a>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }

}

export default Header
