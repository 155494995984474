import React from 'react';
import './App.css';
//import './css/line-icons.css';

import Header from './components/Header/Header';
import Services from './components/Services/Sevices';
import Features from './components/Features/Features';
import Contacts from './components/Contacts/Contacts';
import Footer from './components/Footer/Footer';
import Callback from './components/Callback/Callback';
import ToTopButton from './components/ToTopButton/ToTopButton';
import Portfolio from './components/Portfolio/Portfolio'
import Calculator from './components/Calculator/Calculator';
import { YMaps } from "react-yandex-maps";


class App extends React.Component {
  state = {width: window.innerWidth}

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };


  render(){ 
    const { width } = this.state;
    const isMobile = width <= 992;
    return ( 
      <YMaps> 
      <div className="App" >
        <Header isMobile={isMobile} />
        <Services />
        <Features /> 
        <Portfolio isMobile={isMobile}/>
        <hr />
        <Calculator isMobile={isMobile} />
        <Callback />
        <Contacts /> 
        <Footer />
        <ToTopButton />
      </div>
      </YMaps> 
    );
  }

}

export default App;
