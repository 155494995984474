import React from 'react';

import './ToTopButton.css';

function handleClick(e) {
  e.preventDefault();
  window.scroll({
    top: 0,
    behavior: "smooth"
  });
}

class ToTopButton extends React.Component {

   state = {
    drawButton : false
   }
  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll)
  }
  listenToScroll = () => {
    const winScroll =  document.body.scrollTop || document.documentElement.scrollTop
    if (winScroll > 200) this.setState({drawButton: true})
    else this.setState({drawButton: false})
  }

  render(){
    const  {drawButton}  = this.state;
    return (
      <div>
         {drawButton && 
            <div className="back-to-top"onClick={handleClick}>
               <i className="lnr lnr-arrow-up"/>
             </div>
        }
    </div>
    );
  }

}

export default ToTopButton;
