import React from "react";

import "./Contacts.css";
import YandexMap from "./YandexMap";

class Contacts extends React.Component {
  render() {
    return (
      <section id="contact" className="section">
        <div className="contact-form">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-sm-4 col-xs-12">
                <div className="contact-us">
                  <img className="img-fulid" src="img/logo3.png" alt="" />
                  <div className="contact-address">
                    <p className="address"></p>
                    <p className="address">Cанкт-Петербург,</p>
                    <p className="address">Октябрьская наб., д. 104 к. 41 </p>
                    <p className="address">
                      Тел.: <a href="tel:+78129858797">+7 (812) 985-87-97</a>
                    </p>
                    <p className="address">
                      Тел.: <a href="tel:+79626858797">+7 (962) 685-87-97</a>
                    </p>
                    <p className="address">
                      E-mail: <span>info@polosovye.ru</span>
                    </p>
                    <p className="address">
                      <a href="https://wa.me/79626858797?text=Здравствуйте.%20Интересуют%20ПВХ%20завесы">
                        <img
                          alt=""
                          className="messengerPic"
                          src="img/whatsapplogo.png"
                        />
                        <span>WhatsApp</span>
                      </a>
                    </p>
                    <p className="address">
                      <a href="https://t.me/Dudinaln">
                        <img
                          alt=""
                          className="messengerPic"
                          src="img/telegramlogo.png"
                        />
                        <span>Telegram</span>
                      </a>
                    </p>
                    <p className="address">
                      <a href="viber://chat?number=79626858797">
                        <img
                          alt=""
                          className="messengerPic"
                          src="img/viberlogo.png"
                        />
                        <span>Viber</span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-sm-8 col-xs-12">
                <div className="contact-block">
                  <div id="map" className="googleMap">
                    <YandexMap />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contacts;
