import React from 'react'



export default class YouTube extends React.Component {
  render() {

    const videoSrc = "https://www.youtube.com/embed/" + this.props.video
      + "?autoplay=" + this.props.autoplay || '0'
      + "&rel=" + this.props.rel || '0'
      + "&modestbranding=" + this.props.modest || '1';
    return (
     
          <div className="resp-container">
            <iframe title='ytbiframe' className="resp-iframe" 
              src={videoSrc}
              frameBorder="0"
            />
          </div>
     
    );
  }
}

