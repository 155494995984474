import React from 'react'
import './Calculator.css'

import SectionHeader from '../SectionHeader/SectionHeader'
import NumberInput from '../NumberInput/NumberInput'

import calcIt from '../../calculator/calcIt'
import loadPrice from '../../calculator/loadPrice'
import { localPrice } from '../../calculator/_localPrice'

const pvcOvercross = [
    { value: '40', title: '40% (на 1 проушину)' },
    { value: '80', title: '80% (на 2 проушины)' },
    { value: '100', title: '100% (полностью)' }
]

const pvcInstallation = [
    { enable: true, value: 'none', title: 'Без монтажа' },
    { enable: true, value: 'standart', title: 'Стандартный' },
    { enable: true, value: 'doubled', title: 'Двойная гребенка' },
    { enable: true, value: 'sliding', title: 'Сдвижная половина' },
    { enable: false, value: 'full', title: 'Передвижная вдоль' },
]


export default class Calculator extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            height: '',
            width: '',
            pvcInstallation: pvcInstallation[0].value,
            pvcOvercross: pvcOvercross[0].value,

            lastCalcedStrips: '',
            lastCalcedPrice: '',
            lastCalcedItem: ''

        }
        this.calc = this.calc.bind(this)
        this.selectChange = this.selectChange.bind(this)
        this.getPrice()
    }

    async getPrice() {
        try {
            this.price = await loadPrice()
            this.price.installationPrice = localPrice.installationPrice
        } catch (e) {
            this.price = localPrice
        }
        //console.log(this.price);
    }

    calc(val, id) {
        // setUp
        if (this.state.hasOwnProperty(id)) {
            this.setState({ [id]: val },
                () => { this.ccalc() });    // setState is an async function, this is a callback, after state is setted
        }
    }

    ccalc() {
        if (this.state.height && this.state.width) {
            const result = calcIt(this.state.width, this.state.height, 200, 'polar', this.state.pvcOvercross, this.state.pvcInstallation, this.price)
            this.setState({
                lastCalcedItem: result.item,
                lastCalcedStrips: result.strips,
                lastCalcedPrice: result.total
            }, () => {  // await setState
                document.querySelector('.resultTotal').classList.remove('animate__pulse')
                setTimeout(() =>
                    document.querySelector('.resultTotal').classList.add('animate__pulse')
                    , 10);
            });

        }
    }

    selectChange(event) {
        this.calc(event.target.options[event.target.selectedIndex].value, event.target.id)
    }

    render() {
        return (
            <section id="calc" className="section">
                <div className="container">

                    <SectionHeader
                        title="Онлайн Расчет"
                        description="Укажите внутренние размеры фургона"
                    />
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className=" text-center" >
                                <div className='control'>
                                    <span className='dimInputTitle'>Ширина, м: </span>
                                    <NumberInput
                                        isMobile={this.props.isMobile}
                                        onChange={this.calc}
                                        placeholder='Шир'
                                        step='0.05' min='0.6' max='5'
                                        id='width'
                                    />
                                </div>
                                <div className='control'>
                                    <span className='dimInputTitle'>Высота, м: </span>
                                    <NumberInput
                                        isMobile={this.props.isMobile}
                                        onChange={this.calc}
                                        placeholder='Выс'
                                        step='0.1' min='1' max='4'
                                        id='height'
                                    />
                                </div>
                            </div>

                            <div>
                                <div className="control">
                                    <span className='calcSelectTitle'>Нахлест: </span>
                                    <select id="pvcOvercross" className="select_7s" onChange={this.selectChange}>
                                        {pvcOvercross.map((item, index) => {
                                            return (
                                                <option key={index} value={item.value}>{item.title}</option>
                                            )
                                        })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div>
                                <div className="control">
                                    <span className='calcSelectTitle'>Монтаж: </span>
                                    <select id="pvcInstallation" className="select_7s" onChange={this.selectChange}>
                                        {pvcInstallation.map((item, index) => {
                                            return (
                                                <option disabled={!(item.enable)} key={index} value={item.value}>{item.title}</option>
                                            )
                                        })
                                        }

                                    </select>
                                </div>
                            </div>
                            {this.state.lastCalcedPrice && (
                                <>
                                    <div className="results ">
                                        <p className="resultTxt animate__animated animate__pulse">{this.state.lastCalcedItem}</p>
                                    </div>
                                    <div className="results" >
                                        <p className="resultTotal animate__animated "><b>{this.state.lastCalcedPrice}</b> руб.</p>
                                    </div>
                                </>
                            )}



                        </div>
                    </div>

                </div>
            </section>
        )
    }

}
