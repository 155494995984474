export const pvcParams = {	
	"200"	:	{
					"size"				: 200,			
					"overcrossOptions"	: [40, 80, 100],
					"pic_name"			: 'p200.png',
					"pic_width"			: 50,
					"pHoles"			: 5,
					"shiftHoles"	:{
										"40"	: 3,			
										"80"	: 1,
										"100"	: 0
									}
					},
	"300"	:	{
					"size"				: 300,			
					"overcrossOptions"	: [30, 60, 85, 100],
					"pic_name"			: 'p300.png',
					"pic_width"			: 70,
					"pHoles"			: 7,
					"shiftHoles"	:{
										"30"	: 5,			
										"60"	: 3,
										"85"	: 1,
										"100"	: 0
									}
					},
	"400"	:	{
					"size"				: 400,			
					"overcrossOptions"	: [40, 60, 80, 100],
					"pic_name"			: 'p400.png',
					"pic_width"			: 100,
					"pHoles"			: 10,
					"shiftHoles"	:{
										"40"	: 6,			
										"60"	: 4,
										"85"	: 2,
										"100"	: 0
									}
					}
};
