export const localPrice = {
    // price for 15.04.2021
    assemble: "300",
    black_200: "190",
    blue_200: "190",
    gray_200: "170",
    gray_300: "240",
    grebenka: "288",
    green_200: "190",
    plastina_200: "39",
    plastina_300: "54",
    plastina_400: "95",
    polar_200: "140",
    polar_300: "289",
    polar_400: "598",
    polar_ribbed_200: "246",
    polar_ribbed_300: "390",
    polar_ribbed_400: "690",
    red_200: "180",
    red_300: "250",
    stainless_grebenka: "420",
    stainless_plastina_200: "50",
    stainless_plastina_300: "75",
    standart_200: "135",
    standart_300: "284",
    standart_400: "594",
    standart_ribbed_200: "230",
    standart_ribbed_300: "380",
    white_200: "190",
    yellow_200: "190",

    installationPrice: {
        none: "0",
        standart: "1000",
        doubled: "1500",
        sliding: "2500",
        full: "6000"
    }
}

