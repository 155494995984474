
async function getPrice() {
    const url = '/php/7s_load_price.php';
    const formData = new FormData();
    formData.append('action', "calc_v2");

    let data = await fetch(url, { method: 'POST', body: formData })
        .then((response) => response.json())
        .catch(error => console.error(error))

    return data
}

function adaptOldPrice(price) {
    const newPrice = {}

    let changeMe = ["_2x", "_3x", "_4x", "_5x", "winter", "groove"];
    let changeTo = ['_', '_', '_', '_', 'polar', 'ribbed'];

    Object.keys(price).forEach((key, index) => {
        let newKey = key
        for (let i = 0; i < changeMe.length; i++) {
            newKey = newKey.replace(changeMe[i], changeTo[i]);
        }
        newPrice[newKey] = price[key]
    })

    return newPrice
}


export default async function loadPrice() {
    const price = await getPrice()
    return adaptOldPrice(price)
}