import React from 'react';

import './Sevices.css';

import SectionHeader from '../SectionHeader/SectionHeader';
import ServiceTab from '../ServiceTab/ServiceTab';

const calcHref = {
  link: '#calc',
  title : 'онлайн'
}
class Sevices extends React.Component {

  render(){
    return (
      <section id="services" className="section">
       <div className="container">

        <SectionHeader title = "Наши Услуги" description = "Оперативно. Качественно. Не дорого." />

        <div className="row">

        <ServiceTab 
          icon = 'lnr lnr-select'
          title = 'Онлайн расчет'
          description = 'Укажите размеры фургона и узнайте стоимость завес '
          link={calcHref}
        />
        
        <ServiceTab 
          icon = 'lnr lnr-layers'
          title = 'Нарезка в размер'
          description = 'Изготовим ПВХ завесы по Вашим размерам заранее, либо померяем сами на месте.'
        />

        <ServiceTab 
          icon = 'lnr lnr-car'
          title = 'Установка в автомобиль'
          description = 'Быстро и качественно смонтируем завесы в Вашем авторефрижераторе.'
        />  

        
        </div>
      </div>
    </section>
      
        
    );
  }

}

export default Sevices;
