import React from 'react';

import './Features.css';

import SectionHeader from '../SectionHeader/SectionHeader';
import FeatureTab from '../FeatureTab/FeatureTab';

class Features extends React.Component {

  render(){
    return (
      <section id="features" className="section">
      <div className="container">

      <SectionHeader 
        title = "Термо шторки для рефрижератора" 
        description = "Полосовые ПВХ завесы зарекомендовали себя как эффективное и недорогое решение" 
        addClass = "textShadow"
      />

        <div className="row">
          <div className="col-lg-12 col-md-12 col-xs-12">
            <div className="container">
              <div className="row">

                <FeatureTab 
                  icon = 'lnr lnr-sync'
                  title = 'Держат холод'
                  description = 'Препятствуют потере холода при разгрузке, а также в ветхих фургонах с не плотно закрывающимися дверьми.'
                />  
                <FeatureTab 
                  icon = 'lnr lnr-gift'
                  title = 'Сохраняют товар'
                  description = 'Груз транспортируется при требуемых температурных параметрах, Заказчик доволен.'
                /> 
                <FeatureTab 
                  icon = 'lnr lnr-cog'
                  title = 'Сберегают оборудование'
                  description = 'Поддерживается стабильная температура в кузове - меньшая нагрузка на холодильную установку.'
                /> 
                <FeatureTab 
                  icon = 'lnr lnr-drop'
                  title = 'Экономят топливо'
                  description = 'Меньше работает компрессор - меньше расход топлива.'
                /> 
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      
        
    );
  }

}

export default Features;
