import React from 'react';

import './Nav.css';

class NavMobile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapse: false,
      showMobileMenu: false
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll)
  }
  listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop
    if (winScroll > 200) this.setState({ collapse: true })
    else this.setState({ collapse: false })
  }

  render() {
    const collapse = this.state.collapse;
    return (
      <div className={"navbar navbar-expand-lg fixed-top scrolling-navbar indigo " + (collapse ? 'top-nav-collapse' : '')}>
        <div className="container">
          <div className="navbar-header">
            <div className="slicknav_menu">
              <span className='slicknav_btn slicknav_collapsed' onClick={() => this.setState({ showMobileMenu: !this.state.showMobileMenu })}>
                <i className='lnr lnr-menu'></i>
              </span>

              {this.state.showMobileMenu && (
                <ul className="slicknav_nav slicknav_hidden" aria-hidden="true" role="menu" >

                  {Object.keys(this.props.navData).map((link, index) => {
                    return (
                      <li key={index}>
                        <a className="page-scroll" href={link} role="menuitem" tabIndex="-1">{this.props.navData[link]}</a>
                      </li>
                    )
                  })}
                </ul>
              )}
            </div>

            <a href="index.html" className="navbar-brand"><img className="img-fulid" src="img/logo3.png" alt=""></img> </a>
          </div>
        </div>
      </div>
    )
  }
}

export default NavMobile;

