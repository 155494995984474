import React from 'react';

import './FeatureTab.css';
import ScrollAnimation from 'react-animate-on-scroll';

class FeatureTab extends React.Component {

  render() {
    return (

      <div className="col-lg-6 col-sm-6 col-xs-12 box-item">
        <ScrollAnimation animateIn='animate__animated animate__fadeInDown'>
          <span className="icon">
            <i className={this.props.icon}></i>
          </span>
          <div className="text">
            <h4 className="textShadow">{this.props.title}</h4>
            <p className="textShadow">{this.props.description}</p>
          </div>
        </ScrollAnimation>
      </div>


    )
  }

}

export default FeatureTab;
