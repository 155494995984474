import React from 'react'
import './NumberInput.css'

export default class NumberInput extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.onTextChange = this.onTextChange.bind(this);

        this.state = {
            value: parseFloat(this.props.value) || '',
            step: parseFloat(this.props.step) || 1,
            min: parseFloat(this.props.min) || 0,
            max: parseFloat(this.props.max) || 999,

            styles: {
                width: (this.props.isMobile) ? '50px' : this.props.width || '65px',
                height: this.props.height || '35px'
            }
        }
    }

    handleClick(event) {
        const { type } = event.target.dataset
        let currentValue = parseFloat(this.state.value) || this.state.min
        if (type === 'inc') {

            if (currentValue + this.state.step <= this.state.max) {
                const newVal = (currentValue + this.state.step).toFixed(2)
                this.setState({ value: Number(newVal) })

                this.props.onChange(newVal, this.props.id) // Callback
            }

        } else if (type === 'dec') {

            if (currentValue - this.state.step >= this.state.min) {
                const newVal = (currentValue - this.state.step).toFixed(2)
                this.setState({ value: Number(newVal) })

                this.props.onChange(newVal, this.props.id) // Callback
            }
        }

    }
    onTextChange(e) {

        let newVal = e.target.value.replace(/[^0-9.,]/g, "");
        newVal = newVal.replace(/,/g, '.')

        //if (!newVal) newVal = this.state.min

        if (parseFloat(newVal) > this.state.max) newVal = this.state.max

        if (newVal[newVal.length - 1] !== '.' && parseFloat(newVal) < this.state.min) {
            //newVal = this.state.min
        }

        this.setState({ value: newVal }) // set as string to draw . dot

        this.props.onChange(parseFloat(newVal), this.props.id) // Callback
    }
    render() {
        if (this.props.isMobile) {
            return (
                <div className="number_input_mobile">
                    <span className='plus_minus_number_input_mobile' >
                        <span className='lnr lnr-chevron-down-circle' data-type="dec" onClick={this.handleClick} />
                    </span>
                    <input type="text" className="input_number_input" inputMode="numeric" pattern="[0&shy;9]*"
                        placeholder={this.props.placeholder}
                        value={this.state.value} style={this.state.styles} onChange={this.onTextChange}
                    />
                    <span className='plus_minus_number_input_mobile' >
                        <span className='lnr lnr-chevron-up-circle' data-type="inc" onClick={this.handleClick} />
                    </span>
                </div>
            )
        }

        else return (
            <div className="number_input" style={this.state.styles}>
                <input type="text" className="input_number_input"
                    placeholder={this.props.placeholder}
                    value={this.state.value} style={this.state.styles} onChange={this.onTextChange}
                />
                <div className="plus_number_input" data-type="inc" onClick={this.handleClick}></div>
                <div className="minus_number_input" data-type="dec" onClick={this.handleClick}></div>
            </div>
        )
    }

}

