import React from 'react'
import './Portfolio.css'
import PortfolioItem from './PortfolioItem'
import SectionHeader from '../SectionHeader/SectionHeader'


export default class Portfolio extends React.Component {

    render() {
        return (
            <section id="portfolios" className="section">
                <div className="container">
                    <SectionHeader
                        title="Наши работы"
                        description=""
                    />
                    <h4 className='pheader'>Стандартный монтаж:</h4>

                    <div className="row">
                        <PortfolioItem isMobile={this.props.isMobile} image='img/_portfolio/img_11.jpg' />
                        <PortfolioItem isMobile={this.props.isMobile} image='img/_portfolio/img_12.jpg' />
                        <PortfolioItem isMobile={this.props.isMobile} image='img/_portfolio/img_13.jpg' />
                        <PortfolioItem isMobile={this.props.isMobile} image='img/_portfolio/img_15.jpg' />
                    </div>

                    <hr className="lines" />
                    <h4 className='pheader'>Сдвижная штора</h4>
                    <p className='pheader'>Половинка шторы сдвигается вбок, освобождая проход:</p>

                    <div className="row">
                        <PortfolioItem isMobile={this.props.isMobile} thumb='img/_portfolio/img_21.jpg' video='LF3dsfHeEHg' />
                        <PortfolioItem isMobile={this.props.isMobile} image='img/_portfolio/img_22.jpg' />
                        <PortfolioItem isMobile={this.props.isMobile} image='img/_portfolio/img_24.jpg' />
                        <PortfolioItem isMobile={this.props.isMobile} image='img/_portfolio/img_25.jpg' />
                    </div>

                    <hr className="lines" />
                    <h4 className='pheader'>Передвижная завеса</h4>
                    <p className='pheader'>
                        Позволяет изменять охлаждаемый объем кузова в зависимости от загрузки
                <br />
                Меньший объем быстрее охлаждается и требует меньшей мощности для поддержания температуры:
            </p>

                    <div className="row">
                        <PortfolioItem isMobile={this.props.isMobile} thumb='img/_portfolio/img_31.jpg' video='ifXC_CeNp6o' />
                        <PortfolioItem isMobile={this.props.isMobile} image='img/_portfolio/img_32.jpg' />
                        <PortfolioItem isMobile={this.props.isMobile} image='img/_portfolio/img_33.jpg' />
                        <PortfolioItem isMobile={this.props.isMobile} image='img/_portfolio/img_34.jpg' />
                    </div>

                </div>
            </section>
        )
    }

}