import React from "react";
import './Portfolio.css'

import YoutubePlayer from './YoutubePlayer'


class Item extends React.Component {

    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this)
    }

    state = { isOpen: false }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ isOpen: false })
        }
    }
    handleKeyDown(event) {
        if (event.key === 'Escape') this.setState({ isOpen: false })
    }
    bgClick(){
        //console.log('bgclcl');
    }
    render() {
        const isMobile = this.props.isMobile;
        const thumb = this.props.thumb ? this.props.thumb  : this.props.image 
        return (
            <>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 mix" >
                    <div className="portfolio-item" onClick={() => this.setState({ isOpen: true })}>
                        <div className="shot-item">
                            <img alt='' src={thumb} />
                            <div className="overlay lightbox"  >
                                <i className="lnr lnr-eye item-icon"></i>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isOpen && (
                   
                    <div className='modal'>
                        
                        <div className={'modalItem '+(isMobile ? 'modalItemMobile' : '')}>
                            <div className="row justify-content-center  animate__pulse animate__animated animate__faster">
                                <div className="col-sm-8 col-lg-8" ref={this.wrapperRef}>
                                    {this.props.image && <img className='modal-img' alt='' src={this.props.image} />}
                                    {this.props.video && <YoutubePlayer video={this.props.video} />}
                                </div>
                            </div>
                        </div>
                      
                    </div>
                  
                )}
            </>
        )
    }

}

export default Item
