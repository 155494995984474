import React from 'react'
import './Callback.css';

function doFormat(x, pattern, mask) {
    let strippedValue = x.replace(/[^0-9]/g, "");
    let chars = strippedValue.split('');
    let count = 0;

    let formatted = '';
    for (let i = 0; i < pattern.length; i++) {
        const c = pattern[i];
        if (chars[count]) {
            if (/\*/.test(c)) {
                formatted += chars[count];
                count++;
            } else {
                formatted += c;
            }
        } else if (mask) {
            if (mask.split('')[i])
                formatted += mask.split('')[i];
        }
    }
    return formatted;
}

export default class PhoneInput extends React.Component {
    constructor(props) {
        super(props)

        this.onTextChange = this.onTextChange.bind(this)
    }

    render() {
        return (
            <input name = 'phone' type="text" defaultValue="(___) ___-____"
                data-format="(***) ***-****" data-mask="(___) ___-____"
                onChange={this.onTextChange}
                className="phone"
            />
        )
        
    }

    onTextChange(e) {
        const elem = e.target
        const val = doFormat(elem.value, elem.getAttribute('data-format'));
        elem.value = doFormat(elem.value, elem.getAttribute('data-format'), elem.getAttribute('data-mask'));

        if (elem.createTextRange) {
            let range = elem.createTextRange();
            range.move('character', val.length);
            range.select();
        } else if (elem.selectionStart) {
            elem.focus();
            elem.setSelectionRange(val.length, val.length);
        }
    }
}


