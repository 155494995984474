import React from 'react';

import './ServiceTab.css';
import ScrollAnimation from 'react-animate-on-scroll';

class ServiceTab extends React.Component {

  render() {
    return (


      <div className="col-md-4 col-sm-6">
        <ScrollAnimation animateIn='animate__animated animate__fadeInUp'>
          <div className="item-boxes">
            <div className="icon">
              <i className={this.props.icon}></i>
            </div>
            <h4>{this.props.title}</h4>
            <p>{this.props.description}
              {this.props.link && (
                <a href={this.props.link.link} className="">{this.props.link.title}</a>
              )}

            </p>
          </div>
        </ScrollAnimation>
      </div>

    );
  }

}

export default ServiceTab;
